import {
    createPlugin,
    microsoftAuthApiRef,
    fetchApiRef,
    createApiFactory
  } from '@backstage/core-plugin-api';

import { microsoftCalendarApiRef, MicrosoftCalendarApiClient } from '@backstage-community/plugin-microsoft-calendar';


export { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';
export const microsoftCalendarPlugin = createPlugin({
    id: 'microsoft-calendar',
    apis: [
      createApiFactory({
        api: microsoftCalendarApiRef,
        deps: { authApi: microsoftAuthApiRef, fetchApi: fetchApiRef },
        factory(deps) {
          return new MicrosoftCalendarApiClient(deps);
        },
      }),
    ],
  });

//export { microsoftCalendarPlugin } from '@backstage-community/plugin-microsoft-calendar';